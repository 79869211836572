<template>
  <BaseList
    color="primary"
    :title="$route.meta.title"
    :section="$route.meta.section"
    :headers="headers"
    :items="languages"
    :loading="loading"
    :totalRows="totalRows"
    addText="Agregar Idioma"
    @add="handleNew"
    @edit="handleEdit"
    @refresh="getData"
    @delete="handleDelete"
    @search="handleSearch"
    @options="handleOptions"
  >
    <template #custom-button="{item}">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="ml-2"
            icon
            color="#8990AD"
            v-on="on"
            @click="handleTranslate(item)"
            v-can="'translate-language'"
          >
            <v-icon v-text="'mdi-translate'" />
          </v-btn>
        </template>
        <span>Editar traducciones</span>
      </v-tooltip>
    </template>
  </BaseList>
</template>
<script>
import BaseList from '@/components/commons/BaseList'
import LanguageService from '@/services/language'

export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      loading: false,
      languages: [],
      totalRows: 0,
      search: '',
      headers: [
        { text: 'Código', value: 'code' },
        { text: 'Nombre', value: 'name' },
        { text: 'Status', value: 'active' }
      ]
    }
  },
  methods: {
    async getData() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.options

      this.loading = true
      try {
        const { data } = await LanguageService.fetch(
          { 'filter[query]': this.search },
          sortBy[0] ? sortBy[0] : 'name',
          sortDesc[0],
          page,
          itemsPerPage == -1 ? this.totalRows : itemsPerPage
        )
        this.languages = data.data
        this.totalRows = data.total
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },

    handleNew() {
      this.$router.push({ name: 'LanguageCreate' })
    },

    handleEdit(item) {
      this.$router.push({
        name: 'LanguageEdit',
        params: { id: item.id }
      })
    },

    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text:
          '¿ Desea eliminar el idioma ? se perderían TODAS las traducciones asociadas a ese idioma'
      })

      if (!res) return false

      try {
        await LanguageService.delete(item.id)
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error(e.title)
      }
    },

    handleOptions(options) {
      this.options = options
      this.getData()
    },

    handleSearch(value) {
      this.search = value
      this.getData()
    },

    handleTranslate(item) {
      this.$router.push({
        name: 'Translations',
        params: { id: item.id, code: item.code }
      })
    }
  }
}
</script>

<style></style>
