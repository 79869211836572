<template>
  <v-container>
    <BaseForm
      :title="$route.meta.title"
      :loading="loading"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Código"
              rules="required|min:2|max:2|alpha_spaces"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Código *"
                placeholder="Código del Idioma"
                v-model="language.code"
                v-mask="'aa'"
                maxlength="2"
                dense
                :error-messages="errors[0]"
                autofocus
              />
            </ValidationProvider>
          </v-col>
          <!-- NAME -->
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Nombre"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Nombre *"
                placeholder="Nombre del Idioma"
                v-model="language.name"
                maxlength="45"
                dense
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
          <!-- DESCRIPTION -->
          <v-col cols="12">
            <v-textarea
              label="Descripción"
              rows="3"
              placeholder="Descripción del Idioma"
              v-model="language.description"
              maxlength="255"
              dense
            />
          </v-col>
          <v-col cols="12 d-flex justify-end">
            <v-switch
              v-model="language.active"
              color="primary"
              label="Activo"
            />
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import LanguageService from '@/services/language'

export default {
  components: { BaseForm },
  data() {
    return {
      loading: false,
      language: { active: true }
    }
  },
  async created() {
    this.loading = true
    try {
      const id = this.$route.params.id
      if (id) {
        this.language = await LanguageService.find(id)
      }
    } catch (error) {
      console.log(error)
      this.$dialog.message.error('No se pudo cargar el registro.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await LanguageService.save(this.language)
        this.$dialog.notify.success('Registro guardado con éxito.')
        this.$router.go(-1)
      } catch {
        this.$dialog.message.error('No se pudo guardar el registro.')
      }
    }
  }
}
</script>
